@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --body-background: theme("colors.white");
    --body-color: theme("colors.gray.700");
    --heading-color: theme("colors.blue.900");
    --action-color: theme("colors.blue.500");
    --accent-color: theme("colors.yellow.400");
    --link-hover: theme("colors.blue.600");
    --border-color: theme("colors.yellow.300");
  }

  body {
    @apply bg-[var(--body-background)] text-[var(--body-color)] font-sans text-lg leading-relaxed m-2;
  }

  a {
    @apply text-[var(--action-color)] underline decoration-yellow-500;
  }

  h1 {
    @apply my-4 mb-12 text-center font-black text-4xl text-[var(--heading-color)] leading-tight;
  }

  h2 {
    @apply my-4 mb-12 text-center font-black text-2xl text-[var(--heading-color)] leading-tight;
  }

  h3 {
    @apply my-4 mb-6 font-bold text-xl text-[var(--heading-color)] leading-tight;
  }

  ol {
    @apply list-decimal ml-8;
    li {
      @apply my-2;
    }
  }

  ul {
    @apply list-disc list-inside ml-8;
    li {
      @apply my-2;
    }
  }

  p {
    @apply my-4;
  }

  article {
    @apply my-4 text-left;
  }

  blockquote {
    @apply float-right  flex flex-col justify-between gap-4 rounded-2xl bg-yellow-500 ml-8 mb-8  my-4 text-left w-full max-w-sm text-white text-sm font-semibold;

    h2 {
      @apply text-left text-3xl tracking-tight text-white mb-0 p-6 pb-2;
    }

    p {
      @apply text-base leading-7 text-gray-400 pl-6 pr-6;
    }

    ol {
      @apply list-decimal list-inside ml-8 -mt-8 pt-0;
    }
    li {
      @apply pr-6;
    }

    /* Style for the first paragraph after h2 */
    h2 + p {
      @apply text-lg tracking-tight text-white mt-1;
    }

    p:only-child img:only-child {
      @apply w-full h-full object-cover rounded-xl border border-gray-300 p-0;
      margin: -2rem 0; /* Negative margin to offset parent padding */
      width: calc(100% + 10rem); /* Compensate for negative margin */
      height: calc(100% + 3rem); /* Compensate for negative margin */
    }
    p:only-child {
      @apply pl-0 pr-0;
    }
  }

  /* Clear float after blockquote */
  blockquote::after {
    content: "";
    @apply block clear-both;
  }

  /* New styles for code blocks */
  pre,
  code {
    @apply font-mono text-sm bg-gray-100 rounded p-1;
  }

  pre {
    @apply p-4 overflow-x-auto;
  }
}

@layer components {
  body > header {
    @apply m-4 text-center;
  }

  body > header img {
    @apply inline-block w-full max-w-md;
  }

  body > nav ul {
    @apply my-8 p-0 list-none flex justify-center gap-6 text-xl font-bold;
  }

  body > nav a {
    @apply no-underline;
  }

  main {
    @apply my-auto mx-auto max-w-5xl min-h-[calc(100vh-90vh)] p-6 bg-white;
  }

  @screen sm {
    main {
      @apply p-[25px];
    }
  }

  footer {
    @apply text-center mb-16 text-base;
  }

  hr {
    @apply border-none border-t-2 border-gray-300 my-12;
  }

  /* New flex container styles */
  .flex-container {
    @apply flex flex-col-reverse justify-between gap-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44;
  }

  .flex-item-large {
    @apply flex-none text-3xl font-bold tracking-tight text-white;
  }

  .flex-item-medium {
    @apply sm:w-80 sm:shrink lg:w-auto lg:flex-none;
  }

  .flex-item-text-large {
    @apply text-lg font-semibold tracking-tight text-white;
  }

  .flex-item-text-small {
    @apply mt-2 text-base leading-7 text-gray-400;
  }
}

/* Reusable Classes */
.social-link {
  @apply text-gray-400 hover:text-gray-500;
}

.icon {
  @apply h-6 w-6;
}

/* Customizing container padding for responsive layout */
.container {
  @apply mx-auto;
}
